"use client";

import React, { useState, useRef, useEffect } from "react";
import { useLocale } from 'next-intl';
import { usePathname, useRouter } from '@/i18n/routing';
import dynamic from "next/dynamic";
import Image from "next/image";

const DropDownArrow = dynamic(() =>
  import('../../../../../../assets/Icons/dropdownArrow.svg')
);

const LanguageSwitcherMobile = () => {
  const router = useRouter();
  const pathname = usePathname();
  const currentLocale = useLocale();
  const isRTL = currentLocale === 'ar'

  const [isOpen, setIsOpen] = useState(false);
  const switcherRef = useRef(null);

  const languages = [
    { label: "English", value: "en", flag: { name: 'gb', url: '/flags/gb.svg', alternativeText: "Great Britain Flag" } },
    { label: "العربية", value: "ar", flag: { name: 'uae', url: '/flags/uae.svg', alternativeText: "United Arab Emirates Flag" }},
  ];

  const currentLanguage = languages.find(lang => lang.value === currentLocale);

  const switchLanguage = (locale) => {
    if (locale === currentLocale) return;

    router.replace(pathname, { locale: locale });
    setIsOpen(false);
  };

  useEffect(() => {
    // Define the handler function
    const handleClickOutside = (event) => {
      // Check if the switcherRef exists and whether the clicked target is outside of it
      if (switcherRef.current && !switcherRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
  
    // Add the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);
  
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [switcherRef]);

  return (
    <div
      className={`language-switcher-mobile ${isRTL ? 'ar' : ''}`}
      ref={switcherRef}
    >
      <button
        className="switcher-button"
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Image src={currentLanguage.flag.url} fetchpriority="high" data-nimg="1" style={{color: 'transparent'}}  alt={currentLanguage.flag.alternativeText} width={20} height={15} className={`flag ${isRTL ? 'ar' : ''}`} />
        <span className="text">{currentLanguage.value}</span>
        <span className={`icon ${isRTL ? 'ar' : ''}`}>
          <DropDownArrow />
        </span>
      </button>
      {isOpen && (
        <ul className="switcher-dropdown">
          {languages.map((lang) => (
            <li 
              key={lang.value} 
              className={`switcher-option gradientBorder ${isRTL ? 'ar' : ''}`}
              onClick={() => switchLanguage(lang.value)}
              aria-current={lang.value === currentLocale ? "true" : "false"}
              >
                {lang?.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSwitcherMobile;